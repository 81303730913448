var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var TIME_QUERIES = ['last_3', 'last_5', 'last_10', 'current_season'];
var DEFAULT_TIME_QUERY = 'last_3';
var CLUB_FRIENDLY_ID = '5f34ef232b928b3223dd632d';
var GameStatsQuerySelector = (function () {
    function GameStatsQuerySelector(SeasonService, $scope, $mdMenu) {
        var _this = this;
        this.SeasonService = SeasonService;
        this.$scope = $scope;
        this.$mdMenu = $mdMenu;
        this.initialValue = {};
        this.onMenuClose = function () {
            _this.timeQuery = _this.initialValue.timeQuery;
            _this.gameSelections = _this.initialValue.gameSelections;
            _this.selectedLeagueId = _this.initialValue.selectedLeagueId;
            _this.selectedLeague = _this.initialValue.selectedLeague;
            _this.onlyPlayedGames = _this.initialValue.onlyPlayedGames;
            _this.filterGame();
        };
        this.timeQueries = TIME_QUERIES;
        this.gameSelections = {};
        this.selectedSeason = this.SeasonService.getSeason();
        this.timeQueries = TIME_QUERIES;
        this.timeQuery = this.timeQuery || DEFAULT_TIME_QUERY;
        this.selectedLeagueId = 'all';
        this.filteredGames = [];
        this.onlyPlayedGames = false;
    }
    GameStatsQuerySelector.prototype.$onInit = function () {
        this.$scope.$on('$mdMenuClose', this.onMenuClose);
        this.onChange({
            $season: this.selectedSeason,
            $timeQuery: this.timeQuery || DEFAULT_TIME_QUERY,
        });
    };
    GameStatsQuerySelector.prototype.$onChanges = function (changes) {
        if (changes.timeQuery) {
            this.timeQuery = changes.timeQuery.currentValue || DEFAULT_TIME_QUERY;
        }
        if (changes.games) {
            this.filterGame();
        }
    };
    GameStatsQuerySelector.prototype.isFriendlyGame = function (game) {
        var _a;
        return game.gameType === 'friendly' || ((_a = game.league) === null || _a === void 0 ? void 0 : _a._id) === '5f34ef232b928b3223dd632d';
    };
    GameStatsQuerySelector.prototype.showMenu = function ($mdMenu) {
        this.initialValue = {
            timeQuery: this.timeQuery,
            gameSelections: __assign({}, this.gameSelections),
            selectedLeagueId: this.selectedLeagueId,
            selectedLeague: this.selectedLeague,
            onlyPlayedGames: this.onlyPlayedGames,
        };
        $mdMenu.open();
    };
    GameStatsQuerySelector.prototype.filterGame = function () {
        var _this = this;
        if (!this.games) {
            this.filteredGames = [];
            return;
        }
        this.filteredGames = this.games.filter(function (g) {
            if (_this.selectedLeagueId === 'all') {
                return true;
            }
            return _this.selectedLeagueId === CLUB_FRIENDLY_ID
                ? g.league._id === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
                : g.league._id === _this.selectedLeagueId && g.gameType !== 'friendly';
        });
    };
    GameStatsQuerySelector.prototype.selectTimeQuery = function (timeQuery) {
        this.gameSelections = {};
        this.timeQuery = timeQuery;
    };
    GameStatsQuerySelector.prototype.onLeagueSelectionChange = function () {
        var _this = this;
        this.filterGame();
        this.selectedLeague = this.leagues.find(function (l) { return l._id === _this.selectedLeagueId; });
        this.gameSelections = {};
        if (!this.timeQuery) {
            this.timeQuery = DEFAULT_TIME_QUERY;
        }
    };
    GameStatsQuerySelector.prototype.toggleGameSelection = function (game) {
        this.gameSelections[game.gameId] = !this.gameSelections[game.gameId];
        this.timeQuery = this.totalSelectedGames > 0 ? null : DEFAULT_TIME_QUERY;
    };
    GameStatsQuerySelector.prototype.apply = function () {
        var _this = this;
        this.onChange({
            $league: this.selectedLeagueId,
            $timeQuery: this.timeQuery,
            $games: this.games.filter(function (g) { return !!_this.gameSelections[g.gameId]; }),
            $onlyPlayedGames: this.onlyPlayedGames,
        });
        this.$mdMenu.hide();
    };
    Object.defineProperty(GameStatsQuerySelector.prototype, "totalSelectedGames", {
        get: function () {
            var _this = this;
            return Object.keys(this.gameSelections).filter(function (key) { return !!_this.gameSelections[key]; }).length;
        },
        enumerable: false,
        configurable: true
    });
    return GameStatsQuerySelector;
}());
angular.module('app.general').component('gamestatsQuerySelector', {
    templateUrl: 'general/components/gamestats-query-selector.html',
    controller: GameStatsQuerySelector,
    bindings: {
        games: '<',
        leagues: '<',
        isDisabled: '<',
        timeQuery: '<',
        onlyPlayedGames: '<',
        onlyPlayedGamesEnabled: '<',
        onChange: '&',
    },
});